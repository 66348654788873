import React from 'react';

const Home: React.FC = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row bg-white mt-12" style={{ minHeight: "800px"}}>
          {/* Image Section - Shows above on mobile, moves to right on desktop */}
          <div className="w-full px-4 mb-8 lg:hidden">
              <div className="w-full h-64 bg-cover bg-center" style={{ 
                backgroundImage: "url(https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80)"
              }}>
                <div className="h-full bg-black opacity-30"></div>
              </div>
          </div>
          
          {/* Text Section */}
          <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
              <div>
                  <h2 className="text-3xl font-semibold text-gray-800 md:text-4xl">Chris Johnson - <span className="text-indigo-600"></span></h2>
                  <p className="mt-2 text-sm text-gray-500 md:text-base">Engineer, Full-Stack Developer, Designer, Analyst, AI Baby-sitter.</p>
                  <p className="mt-2 text-sm text-gray-500 md:text-base">I love innovating with the latest technology to solve problems</p>
                  <div className="flex justify-center lg:justify-start mt-6">
                      <a className="px-4 py-3 bg-gray-900 text-gray-200 text-xs font-semibold rounded hover:bg-gray-800" href="./resume">View Resume</a>
                      <a className="mx-4 px-4 py-3 bg-gray-300 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400" href="mailto:kutyadog@gmail.com">Contact</a>
                  </div>
              </div>
              
          </div>

          {/* Desktop Image Section - Hidden on mobile, shows on desktop */}
          <div className="hidden lg:block lg:w-1/2" style={{ clipPath: "polygon(10% 0, 100% 0%, 100% 100%, 0 100%)"}}>
              <div className="h-full object-cover" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80)"}}>
                  <div className="h-full bg-black opacity-30"></div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Home;
