import React from 'react';

const About: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">About Me</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <img src="6059fdd3-e186-4d98-8363-15177882d928.jpg" alt="Your Name" className="rounded-full mx-auto mb-4 max-w-44" />
        <p className="text-gray-700 mb-4">
          Hello! I'm Chris Johnson, a passionate software developer with a keen interest in AI and interactive technologies. 
          With over 15 years of experience in the tech industry, I've had the opportunity to work on a wide range of 
          projects, from web applications to machine learning models.
        </p>
        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-2">Get in Touch</h2>
          <p className="text-gray-700">
            Feel free to reach out to me at <a className="text-blue-500 hover:underline ml-1" href="mailto:kutyadog@gmmail.com">kutyadog@gmail.com</a> or connect with me on 
            <a href="https://www.linkedin.com/in/chris-johnson-5678b619/" className="text-blue-500 hover:underline ml-1">LinkedIn</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;